@import 'variables';

@mixin font($family, $size, $color) {
  font-family: $family;
  font-size: $size;
  color: $color;
}
//
//h1 {
//  font-size: 64px;
//  line-height: 46px;
//  letter-spacing: -0.04em;
//  color: $white;
//  @media (min-width: 600px) {
//    font-size: 116px;
//    line-height: 98px;
//    letter-spacing: -0.04em;
//  }
//}
//
//h2 {
//  font-size: 44px;
//  @media (min-width: 600px) {
//    font-size: 80px;
//  }
//}
