// Colors
$black: #000;
$white: #FFFFFF;
$grey: #A3A3A3;
$grey: #4D4D4D;
$dark-grey: #292929;
$yellow: #D2FF00;
$blue: #00DEFF;
$purple: #7625FA;

$blue2: #03BEE8;
$pink: #F51F7D;

$telegram: #0088CC;
$vk: #4F78A3;

// Fonts
$Druk-Wide-Cy-Web-Medium: Druk Wide Cy Web Medium;
$Druk-Wide-Cy-Web-Bold: Druk Wide Cy Web Bold;
$TT-Travels-Medium: TT Travels Medium;
$TT-Travels-Bold: TT Travels Bold;
$TT-Travels-ExtraBold: TT Travels ExtraBold;
