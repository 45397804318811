@font-face {
  font-family: Druk Wide Cy Web Medium;
  src: url(../assets/fonts/DrukWideCyWeb/DrukWideCyWebMedium.ttf);
}

@font-face {
  font-family: Druk Wide Cy Web Bold;
  src: url(../assets/fonts/DrukWideCyWeb/DrukWideCyWebBold.ttf);
}

@font-face {
  font-family: TT Travels Medium;
  src: url(../assets/fonts/TTTravels/TTTravels-Medium.ttf);
}

@font-face {
  font-family: TT Travels Bold;
  src: url(../assets/fonts/TTTravels/TTTravels-Bold.ttf);
}

@font-face {
  font-family: TT Travels ExtraBold;
  src: url(../assets/fonts/TTTravels/TTTravels-ExtraBold.ttf);
}
