@import './variables';
@import './utils';
@import './typography';

.offer-page {
  &_container {
    padding-top: 100px;
  }
  &_h1 {
    margin-top: 20px;
    line-height: 24px;
    font-weight: 700;
    @include font($Druk-Wide-Cy-Web-Medium, 16px, $black);
    @media (min-width: 600px) {
      line-height: 40px;
      font-weight: 700;
      @include font($Druk-Wide-Cy-Web-Medium, 28px, $black);
    }
  }
  &_h2 {
    line-height: 24px;
    font-weight: 700;
    @include font($Druk-Wide-Cy-Web-Medium, 14px, $black);
    @media (min-width: 600px) {
      line-height: 36px;
      font-weight: 700;
      @include font($Druk-Wide-Cy-Web-Medium, 24px, $black);
    }
  }
  &_p {
    line-height: 26px;
    font-weight: 600;
    @include font($TT-Travels-Medium, 16px, $black);
    @media (min-width: 600px) {
      line-height: 32px;
      font-weight: 600;
      @include font($TT-Travels-Medium, 20px, $black);
    }
  }
  &_close-btn {
    background: transparent;
    border: none;
    &-wrap {
      position: fixed;
      background: rgba($white, .8);
      backdrop-filter: blur(128px);
      width: 100%;
      padding: 14px 10px 10px;
      display: flex;
      flex-direction: row-reverse;
      z-index: 2;
      top: 0;
      left: 0;
    }
    &-img {

    }
  }
}
