@import './variables';
@import './utils';
@import './typography';

.paid-page {
  display: flex;
  box-sizing: border-box;
  min-height: 100%;
  background: $black;
  text-align: center;
  @include font($TT-Travels-Medium, 20px, $white);
  @media (min-width: 600px) {
    @include font($TT-Travels-Medium, 20px, $white);
  }
  &_container {
    padding-top: 70px;
    padding-bottom: 80px;
    @media (min-width: 600px) {
      padding-top: 134px;
      padding-bottom: 260px;
    }
    &-grid {
      justify-content: center;
    }
  }
  &_h1 {
    margin-top: 50px;
    font-weight: bold;
    line-height: 40px;
    @include font($Druk-Wide-Cy-Web-Bold, 37px, $white);
    @media (min-width: 600px) {
      margin-top: 15px;
      line-height: 64px;
      @include font($Druk-Wide-Cy-Web-Bold, 60px, $white);
    }
  }
  &_tariff-name {
    margin-top: 16px;
    font-weight: 500;
    line-height: 24px;
    @include font($Druk-Wide-Cy-Web-Medium, 16px, $yellow);
    @media (min-width: 600px) {
      margin-top: 21px;
      line-height: 32px;
      @include font($Druk-Wide-Cy-Web-Medium, 20px, $yellow);
    }
  }
  &_p {
    margin-top: 9px;
    font-weight: 600;
    line-height: 26px;
    @include font($TT-Travels-Bold, 16px, $grey);
    @media (min-width: 600px) {
      margin-top: 8px;
      line-height: 28px;
      @include font($TT-Travels-Bold, 20px, $grey);
    }
  }
  &_botp {
    width: 100%;
    max-width: 375px;
    margin: 46px auto 0;
    font-weight: 600;
    line-height: 26px;
    @include font($TT-Travels-Bold, 16px, $white);
    @media (min-width: 600px) {
      margin-top: 46px;
      line-height: 32px;
      @include font($TT-Travels-Bold, 20px, $white);
    }
  }
  &_bot-button {
    width: 320px;
    height: 56px;
    margin: 14px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    line-height: 24px;
    border-radius: 0;
    border: none;
    @include font($Druk-Wide-Cy-Web-Medium, 16px, $black);
    @media (min-width: 600px) {
      margin-top: 22px;
      width: 360px;
      height: 76px;
      line-height: 32px;
      @include font($Druk-Wide-Cy-Web-Bold, 20px, $black);
    }
  }
  &_support-service {
    margin-top: 62px;
    font-weight: 600;
    line-height: 26px;
    @include font($TT-Travels-Bold, 16px, $grey);
    @media (min-width: 600px) {
      margin-top: 80px;
      line-height: 28px;
      @include font($TT-Travels-Bold, 20px, $grey);
    }
  }
  &_email {
    margin-top: 14px;
    &-link {
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
      @include font($Druk-Wide-Cy-Web-Medium, 14px, $white);
      @media (min-width: 600px) {
        margin-top: 24px;
        line-height: 24px;
        @include font($Druk-Wide-Cy-Web-Medium, 20px, $white);
      }
    }
  }
  &_tel {
    margin-top: 28px;
    margin-bottom: 0;
    &-link {
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
      @include font($Druk-Wide-Cy-Web-Medium, 14px, $white);
      @media (min-width: 600px) {
        margin-top: 40px;
        line-height: 24px;
        @include font($Druk-Wide-Cy-Web-Medium, 20px, $white);
      }
    }
  }
}

.reply-to-address {
  max-width: 380px;
  margin: 16px auto 0;
  line-height: 24px;
  @include font($Druk-Wide-Cy-Web-Medium, 16px, $white);
  &_link {
    text-decoration: none;
    color: $yellow;
    &:focus,
    &:visited,
    &:hover {
      text-decoration: none;
      color: $yellow;
    }
  }
}

.button {
  &_telegram {
    display: flex;
    text-decoration: none;
    background: $telegram;
    box-shadow:
            0 8px 0 0 rgba($telegram, .2),
            0 16px 0 0 rgba($telegram, .2),
            0 24px 0 0 rgba($telegram, .2);
    &:focus,
    &:visited,
    &:hover {
      background: $telegram;
      box-shadow:
              0 8px 0 0 rgba($telegram, .2),
              0 16px 0 0 rgba($telegram, .2),
    }
  }
  &_vk {
    display: flex;
    text-decoration: none;
    background: $vk;
    box-shadow:
            0 8px 0 0 rgba($vk, .2),
            0 16px 0 0 rgba($vk, .2),
            0 24px 0 0 rgba($vk, .2);
    &:focus,
    &:visited,
    &:hover {
      background: $vk;
      box-shadow:
              0 8px 0 0 rgba($vk, .2),
              0 16px 0 0 rgba($vk, .2),
    }
  }
  &_questionnaire {
    display: flex;
    text-decoration: none;
    background: $yellow;
    line-height: 24px;
    @include font($Druk-Wide-Cy-Web-Medium, 16px, $black);
    box-shadow:
            0 8px 0 0 rgba($yellow, .2),
            0 16px 0 0 rgba($yellow, .2),
            0 24px 0 0 rgba($yellow, .2);
    &:focus,
    &:visited,
    &:hover {
      text-decoration: none;
      background: $yellow;
      box-shadow:
              0 8px 0 0 rgba($yellow, .2),
              0 16px 0 0 rgba($yellow, .2),
    }
    @media (min-width: 600px) {
      line-height: 40px;
      @include font($Druk-Wide-Cy-Web-Medium, 20px, $black);
    }
  }
}
