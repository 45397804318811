@import '../../styles/variables';
@import '../../styles/utils';
@import '../../styles/typography';

.reviews-page {
  padding-top: 100px;
  color: $black;
  background-color: $white;
  &_section {
    display: flex;
    width: calc(100% - 48px);
    max-width: 1246px;
    margin: 0 auto;
    padding: 14px 10px 10px;
    justify-content: space-between;
    align-items: center;
  }
  &_item {
    display: flex;
    flex-direction: column;
    &+& {
      margin-top: 96px;
    }
    @media (min-width: 900px) {
      display: grid;
      grid-template-columns: 240px auto;
      grid-template-rows: 1fr;
      grid-gap: 0 20px;
      gap: 0 20px;
      grid-template-areas: "img text";
    }
    @media (min-width: 1280px) {
      grid-template-columns: 0.4fr 1.6fr;
    }
  }
  &_link {
    display: block;
    text-decoration: none;
    &.purple {
      margin-top: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      @include font($TT-Travels-Medium, 16px, $purple);
      &:focus,
      &:visited,
      &:hover {
        @include font($TT-Travels-Medium, 16px, $purple);
      }
    }
  }
  &_close-btn {
    background: transparent;
    border: none;
    @media (min-width: 1280px) {
      &:after {
        content: url("../../assets/img/vector-review-2.4.svg");
        position: absolute;
        top: 232px;
        right: -80px;
      }
    }
    &-wrap {
      position: fixed;
      background: rgba($white, .8);
      backdrop-filter: blur(128px);
      width: 100%;
      height: 96px;
      //max-width: 1280px;
      margin: 0 auto;
      padding: 0;
      display: flex;
      flex-direction: row;
      z-index: 2;
      top: 0;
      left: 0;
    }
    &-img {

    }
  }
  &_h2 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 32px;
    z-index: 1;
    @include font($Druk-Wide-Cy-Web-Bold, 28px, $black);
    @media (min-width: 1280px) {
      &:before {
        content: url("../../assets/img/vector-review-2.2.svg");
        position: absolute;
        top: 232px;
        left: -100px;
      }
      &:after {
        content: url("../../assets/img/vector-review-2.3.svg");
        position: absolute;
        top: 632px;
        left: 10px;
      }
    }
    &--mobile {
      display: block;
      @media (min-width: 1280px) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @media (min-width: 1280px) {
        display: block;
      }
    }
    & span {
      display: inline-block;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        background: url("../../assets/img/vector-review-2.1.svg") no-repeat center center;
        background-size: 250px 90px;
        width: 250px;
        height: 90px;
        left: -52px;
        top: -24px;
        z-index: -1;
      }
    }
  }
  &_wrap {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow: hidden;
  }
  &_list {
    flex-wrap: nowrap;
    transform: translateZ(0);
  }
  &_img {
    width: 100%;
    height: 100%;
    max-width: 142px;
    max-height: 142px;
    &-wrap {
      width: 100%;
      height: 100%;
      max-width: 152px;
      max-height: 152px;
      padding: 5px;
      background: $white;
      position: relative;
      &.vk {
        &:before {
          content: url("../../assets/img/vk_icon.svg");
          position: absolute;
          right: 5px;
          bottom: 1px;
          z-index: 1;
        }
      }
      &.inst {
        &:before {
          content: url("../../assets/img/instagram_icon.svg");
          position: absolute;
          right: 5px;
          bottom: 1px;
          z-index: 1;
        }
      }
      &.fb {
        &:before {
          content: url("../../assets/img/fb_icon.svg");
          position: absolute;
          right: 5px;
          bottom: 1px;
          z-index: 1;
        }
      }
      &:after {
        content: "";
        width: 40px;
        height: 40px;
        background: $white;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &-block {
      display: flex;
      grid-area: img;
      margin-bottom: 22px;
      @media (min-width: 900px) {
        margin-bottom: 0;
      }
      //float: left;
      //margin-right: 40px;
      //margin-bottom: 40px;
    }
  }
  &_title {

  }
  &_author {
    max-height: 152px;
    padding-right: 13px;
    font-weight: 600;
    line-height: 20px;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
    @include font($TT-Travels-Medium, 16px, $grey);
    &-wrap {
      display: flex;
    }
  }
  &_text {
    grid-area: text;
    min-height: 204px;
    padding-top: 0;
    font-weight: 600;
    line-height: 26px;
    @include font($TT-Travels-Medium, 16px, $black);
  }
}
